/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';

import { Download, DownloadProps } from 'components/partials/Download';
import Layout from 'components/partials/Layout';
import { Container } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Flex = styled('div')(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(40),
  },
}));

const WrapImages = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(10),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginTop: theme.spacing(30),
    width: '50%',
  },
}));

const WrapContent = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: {
    width: '40%',
  },
}));

const WrapLogosDesktop = styled('div')(({ theme }) => ({
  display: 'none',
  width: '100%',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'block',
  },
}));

const WrapLogosMobile = styled('div')(({ theme }) => ({
  display: 'block',
  marginTop: theme.spacing(6),
  width: '100%',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'none',
  },
}));

const DownloadPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout
      title={data.downloadYaml.metadata.title}
      description={data.downloadYaml.metadata.description}
    >
      <Container>
        <Download
          content={data.downloadYaml.content}
          render={({ content, mobileButtons, images }) => {
            return (
              <>
                <Flex>
                  <WrapContent>{content}</WrapContent>
                  <WrapLogosMobile>{mobileButtons}</WrapLogosMobile>
                  <WrapImages>{images}</WrapImages>
                </Flex>
                <WrapLogosDesktop>{mobileButtons}</WrapLogosDesktop>
              </>
            );
          }}
        />
      </Container>
    </Layout>
  );
};

interface Props {
  data: {
    downloadYaml: {
      metadata: MetaData;
      content: DownloadProps['content'];
    };
  };
}

export const query = graphql`
  query DownloadQuery {
    downloadYaml {
      ...DownloadContent
    }
  }
`;

export default DownloadPage;
